#assessment-page-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

#title-container {
    width: 100%;
    text-align: center;
}

.question-card {
    margin-bottom: 2rem;
}

.option-container {
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    margin-left: 10px;
}

.custom-block-btn {
    width: 100%;
}

b {
    color: rgb(0, 46, 109)
}

.form-check-label {
    width: 100%;
}

.card-context-container {
    padding-left: 10px;
    padding-right: 10px;
}