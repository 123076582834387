.statistic-card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0.7em;
    background-color: #fff;
    border: solid 1px rgba(0, 0, 0, 0.175);
    border-radius: 0.375rem;
    color: rgb(46, 46, 46);
}

.statistic-card .info-row {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    font-size: 3rem;
}

.statistic-card .header-row {
    color: rgb(46, 46, 46);
    font-size: 1.7rem;
}