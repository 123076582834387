html {
  background-color: rgb(245, 245, 245);
}

body {
  background-color: rgb(245, 245, 245);
  /* margin: 0.5rem; */
  font-family: -apple-system, BlinkMacSystemFont, 'Lato', sans-serif, 'Segoe UI', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.app-container {
  padding: 0.5rem;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1 {
  color: rgb(0, 46, 109);
}

.row {
  margin-left: 0px;
  margin-right: 0px;
}